import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import { getResourceList } from '../../models/BaseModel'
import { ImageReplacementAnchor, InlineNavigation, Loading, LogoNavigation, DrawerNavigation, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Header.scss'

export default function Header() {
    const [menuLinks, setMenuLinks] = useState([])
    const [logoItems, setLogoItems] = useState([])
    const {isLoading, isSuccess, error, data} = useQuery(['header', {resourceName: 'header'}], getResourceList)
    const mobileNavRef = useRef();
    const errorMessage = 'Please try again later. If the problem persists please contact us at <a href="https://support.ionplustv.com/support/home">our support page</a>.'


    useEffect(() => {
        if (isSuccess) {
            setMenuLinks(data.get('menuLinks'))
            setLogoItems(data.get('logoItems'))
        }
    }, [isSuccess, data])

    const handleScroll = useDebouncedCallback(() => {
        if (window.scrollY > 10) {
            mobileNavRef.current.classList.add('mobile-nav--shadow');
        } else {
            mobileNavRef.current.classList.remove('mobile-nav--shadow');
        }
        }, 300) 

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);
    
    return (
        <header>
            <content-constrainer class="desktop-nav">
                <ImageReplacementAnchor href={`/`} 
                    image={{
                        url: 'https://ewscripps.brightspotcdn.com/4a/d1/4651099144b88a57c0e4d0692369/button-ion-plus-logo-2x.png', 
                        width: 250, 
                        height: 100,
                        altText: 'ION Plus Logo, go to homepage'
                    }} 
                    height={3.1}
                    baselineAdjustment={0.1}
                    classname="main-logo"
                />
                { (isLoading) && <Loading />}
                <header-menu-wrapper>
                    { (isSuccess) && <BlankErrorBoundary>
                        <InlineNavigation 
                            links={menuLinks} 
                            classname="main-navigation" 
                            position='right'
                        />
                    </BlankErrorBoundary> } 
                    { (isSuccess) && <BlankErrorBoundary>
                        <LogoNavigation 
                            items={logoItems}
                        />
                    </BlankErrorBoundary> }
                </header-menu-wrapper>
            </content-constrainer>
            <nav className="mobile-nav" ref={mobileNavRef}>
                <content-constrainer class="content-constrainer--no-border content-constrainer--no-padding">
                    <BlankErrorBoundary >
                        <DrawerNavigation
                            links={[
                                {
                                    type: 'internal-link',
                                    href: '/',
                                    label: 'Home',
                                },
                                ...menuLinks]} 
                            items={logoItems}
                            logo={{
                                image: {
                                    url: 'https://ewscripps.brightspotcdn.com/4a/d1/4651099144b88a57c0e4d0692369/button-ion-plus-logo-2x.png', 
                                    width: 250, 
                                    height: 100,
                                    altText: 'ION Plus Logo, go to homepage'
                                },
                                href: '/',
                                height: 2.3,
                                baselineAdjustment: 0.1
                            }}
                            classname="main-navigation"
                            clipPathStart="polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"
                            clipPathEnd="polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%)"
                            overlayAppendEl={document.querySelector('site-wrapper')}
                        />
                    </BlankErrorBoundary>
                </content-constrainer>
            </nav>
        </header>
    )
}
